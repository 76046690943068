'use strict'

angular.module 'nn.common.directives', []

  .directive 'nnScopeWatch', ->
    link: (scope, element, attrs) ->
      scope.$watch ->
        console.log '$digest cycle', scope

  .directive 'nnToggleExplorer', ($timeout, Explorer, AppState) ->
    restrict: 'A'
    link: (scope, element, attrs) ->
      element.on 'click', (e) ->
        toggle = scope.section.id is AppState.section.id
        $timeout ->
          Explorer.data.collapsed = false
          if toggle
            Explorer.data.active = !Explorer.data.active
          else
            Explorer.data.active = true

  .directive 'nnOpenExplorer', ($timeout, Explorer) ->
    restrict: 'A'
    link: (scope, element, attrs) ->
      element.on 'click', ->
        $timeout ->
          Explorer.data.active = true
          Explorer.data.collapsed = false

  .directive 'nnCloseExplorer', ($timeout, Explorer) ->
    restrict: 'A'
    link: (scope, element, attrs) ->
      element.on 'click', ->
        $timeout ->
          Explorer.data.active = false
          Explorer.data.collapsed = false

  .directive 'nnToggleWideExplorer', ($timeout, Explorer) ->
    restrict: 'A'
    link: (scope, element, attrs) ->
      element.on 'click', ->
        $timeout ->
          Explorer.data.wide = !Explorer.data.wide

  .directive 'nnCollapseExplorer', ($timeout, Explorer) ->
    restrict: 'A'
    link: (scope, element, attrs) ->
      element.on 'click', ->
        $timeout ->
          Explorer.data.active = true
          Explorer.data.collapsed = true

  .directive 'nnToggleCollapseExplorer', ($timeout, Explorer) ->
    restrict: 'A'
    link: (scope, element, attrs) ->
      element.on 'click', ->
        $timeout ->
          Explorer.data.collapsed = !Explorer.data.collapsed

  .directive 'nnRemoveFlatPanel', ($timeout, PageExplorer) ->
    restrict: 'A'
    link: (scope, element, attrs) ->
      element.on 'click', ->
        $timeout ->
          PageExplorer.flatPlanState = false
          scope.pageStatusFilter.data = null if scope.pageStatusFilter?

  .directive 'nnToggleFlatPanel', ($timeout, PageExplorer) ->
    restrict: 'A'
    link: (scope, element, attrs) ->
      element.on 'click', ->
        $timeout ->
          if PageExplorer.flatPlanState
            scope.pageStatusFilter.data = null if scope.pageStatusFilter?
          PageExplorer.copyfitStoriesState = false
          PageExplorer.flatPlanState = !PageExplorer.flatPlanState

  .directive 'nnToggleCopyfitStories', ($timeout, PageExplorer) ->
    restrict: 'A'
    link: (scope, element, attrs) ->
      element.on 'click', ->
        $timeout ->
          PageExplorer.flatPlanState = false
          PageExplorer.copyfitStoriesState = !PageExplorer.copyfitStoriesState

  .directive 'nnCountNumber', ($interval) ->
    restrict: 'A'
    scope: { count: '=' }
    link: (scope, element, attr) ->
      timer = null

      animateValue = animateValue = (start = 0, end = 0, duration) ->
        current = start
        stepTime = Math.abs Math.floor(duration / (end - start))

        timer = $interval ->
          if end > start
            current++
          else if end < start
            current--

          element.html current + (attr.type or null)

          if current is end
            $interval.cancel timer
        , stepTime

      scope.$watch 'count', (newValue, oldValue) ->
        $interval.cancel timer if timer?
        animateValue oldValue, newValue, 50

      scope.$on '$destroy', -> $interval.cancel timer

  .directive 'nnFavourite', ($timeout, Favourites) ->
    (scope, element, attrs) ->
      fav = null

      scope.$watch 'favourites', (nv, ov) ->
        if nv and nv.results
          for item, index in nv.results
            if attrs.pk == item.value
              fav = item.id
              element
              .addClass 'active'
              .removeClass ''

      toggleFavourite = (event) ->
        event.stopPropagation()
        if element.hasClass 'active'
          Favourites.remove(attrs.nnFavourite, fav)
          element
          .removeClass 'active'
          fav = undefined
        else
          Favourites.add(attrs.domain, attrs.nnFavourite, attrs.pk).then (data) ->
            element
            .addClass 'active'
            fav = data.id

      element.on 'click', toggleFavourite

  .directive 'nnLoadImage', ->
    restrict: 'A'
    link: (scope, element, attrs) ->
      parent = angular.element(element).parent()
      attrs.$observe 'src', ->
          parent.addClass 'image-spinner'
      angular.element element
          .on 'load', ->
              parent.removeClass 'image-spinner'
          .on 'error', ->
              angular.element(element).attr 'src', '/images/file-error.png'
              parent.removeClass 'image-spinner'

  .directive 'nnStoryStatusPill', ->
      restrict: 'E'
      scope: {}
      link: (scope, element, attrs) ->
          storyStatus = attrs.status
          className = ''
          switch storyStatus
              when 'waiting'
                  label = 'Ready for Approval'
                  className = 'warning'
              when 'in_progress'
                  label = 'Draft'
                  className = 'secondary'
              when 'published'
                  label = 'Published'
                  className = 'success'
          element.html label
          element.addClass "#{className} round label badges"

  .directive 'nnUserFullName', (User, $filter) ->
    restrict: 'E'
    scope: {}
    replace: true
    template: '<span></span>'
    link: (scope, element, attrs) ->
        attrs.$observe 'authorId', (authorId) ->
            User.getDetails(authorId).then (userDetails) ->
                element.html $filter('nnUserFullName') userDetails
